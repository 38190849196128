
import { switchLanguage } from '@/modules/app/app'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import i18n, { languages } from '@/i18n'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Locale',
  components: { Icon, Pic, PageWithHeader },
  setup () {
    const router = useRouter()
    return {
      lang: i18n.locale,
      locales: languages,
      changeLanguage (lang: string) {
        switchLanguage(lang).then(router.back)
      },
    }
  },
})
